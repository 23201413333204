@import "./index.scss";

.cvImage {
  @media screen and (max-width: 1000px) {
    display: none;
  }
  color: $color-text;
  letter-spacing: 2px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  width: 100%;
}

.cvImage:hover {
  letter-spacing: 1px;
}

.logo {
  width: 75%;
  margin: 5%;
}

.home {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
}

.title {
  @media screen and (max-width: 768px) {
    font-size: 35px;
    padding-top: 0px;
  }
  margin-top: 20px !important;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 65px;
  font-weight: 900;
  color: $color-primary;
}

.sub-title {
  @media screen and (max-width: 1000px) {
    font-size: 3em;
  }
  @media screen and (max-width: 1200px) {
    font-size: 3em;
  }
  font-size: 4em;
  font-weight: 900;
  color: $color-tertiary;
}

.cv {
  @media screen and (max-width: 768px) {
    padding: 15px 20px;
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }
  color: $color-primary;
  background-color: transparent;
  line-height: 2;
  font-size: 1.5em;
  font-weight: 900;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: $color-primary;
  border-image: initial;
  border-radius: 3px;
  padding: 20px 25px;
  text-decoration: none;
  letter-spacing: 1px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  width: fit-content;
  display: inline-block;
  height: 100%;
}

.cv:hover {
  color: $color-tertiary;
  border-color: $color-tertiary;
  text-decoration: none;
}

.description {
  @media screen and (min-width: 1000px) {
    padding-top: 100px;
  }
  max-width: 600px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
}

.social-container {
  @media screen and (max-width: 1000px) {
    text-align: left;
  }
  text-align: center;
  // background: #eee;
  // padding: 25px 50px;
}

.social-container a {
  @media screen and (max-width: 1000px) {
    font-size: 3em;
    margin: 0 1.5rem;
  }
  color: $color-septenary;
  font-size: 4em;
  margin: 0 2rem;
  transition: transform 250ms;
}

.social-container a:hover {
  transform: translateY(-2px);
}

.github-logo:hover {
  color: #333;
}

.mail-logo:hover {
  color: $color-secondary;
}
